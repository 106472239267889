import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {

  static values = {
    placeholder: String
  }

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      
      settings: {
        placeholderText: this.placeholderValue,
        allowDeselect: true,
      }
    })
  }
}