import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "captcha" ]

  connect() {
    const blank_token = ''
    const captcha = this.captchaTarget;
    captcha.addEventListener('verified', (e) => {
      this.setCaptchaResponse(e.token);
    });
    captcha.addEventListener('expired', (e) => {
      this.setCaptchaResponse(blank_token);
    });
    captcha.addEventListener('error', (e) => {
      this.setCaptchaResponse(blank_token);
    });
  }

  setCaptchaResponse(token){
    this.dispatch("setCaptchaResponse", { detail: { captchaResponse: token } });
  }
}
