import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "showSignUpForm", "showLoginForm", "submitButton" ]

  static values = { captchaResponseSignUp: String }

  switchForms(event) {
    event.preventDefault();
    if (this.showSignUpFormTarget.hidden) {
      this.showLoginFormTarget.hidden = true;
      this.showSignUpFormTarget.hidden = false;
    }
    else {
      this.showSignUpFormTarget.hidden = true;
      this.showLoginFormTarget.hidden = false;
    }
  }

  setCaptchaResponse({ detail: { captchaResponse } }) {
    this.captchaResponseSignUpValue = captchaResponse

    if (this.captchaResponseSignUpValue === "") {
      this.submitButtonTarget.classList.add("button-gray");
    }
    else {
      this.submitButtonTarget.classList.remove("button-gray");
    }
  }

  signUp(event) {
    if (this.captchaResponseSignUpValue == '') {
      event.preventDefault();
    }
  }
}
