import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "symbolsCount", "textInput" ]

  static values = {
    maxLength: String,
    minLength: String,
    initialText: String
  }

  connect() {
    this.textInputTarget.value = this.initialTextValue
    this.symbolsCountTarget.innerHTML = this.textInputTarget.value.length
    this.countSymbols()
  }

  countSymbols() {
    this.symbolsCountTarget.innerHTML = this.textInputTarget.value.length

    if (this.textInputTarget.value.length < this.minLengthValue || this.textInputTarget.value.length > this.maxLengthValue) {
      this.symbolsCountTarget.classList.add('text--invalid') 
    }
    else {
      this.symbolsCountTarget.classList.remove('text--invalid')
    }
  }
}