// expandable reviews
document.addEventListener('DOMContentLoaded', () => {
  // remove unwanted bootstrap classes
  const container = document.body.querySelector('.js-container')
  const sofa = document.body.querySelector('.js-sofa')
  if (container && sofa) {
    container.classList.remove('container')
    container.firstElementChild.classList.remove('row')
  }
})
