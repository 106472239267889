import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "showLink", "hideLink", "showSection" ]

  showSectionTargetConnected() {
    this.showSection = true;
    this.showSectionTarget.hidden = this.showSection
    if (this.hasHideLinkTarget) this.hideLinkTarget.hidden = this.showSection
  }

  toggle() {
    if (this.hasShowLinkTarget) this.showLinkTarget.hidden = this.showSection
    if (this.hasHideLinkTarget) this.hideLinkTarget.hidden = !this.showSection
    this.showSectionTarget.hidden = !this.showSection
    this.showSection = !this.showSection
  }
}
