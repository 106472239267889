import Rails from "@rails/ujs"
import Sortable from 'sortablejs';
import { Controller } from "stimulus"

export default class extends Controller {

  static values = { 
    updateUrl: String,
    payloadKey: String,
  }

  connect() {
    var that = this;
    window.holo = this;
    var sortable = Sortable.create(this.element.querySelector('.sortable'), {
      onUpdate: function(evt) {
        var payload = {};
        payload[that.payloadKeyValue] = this.toArray();

        fetch(that.updateUrlValue, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": Rails.csrfToken()
          },
          body: JSON.stringify(payload)
        })
      }
    });
  }
}
