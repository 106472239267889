import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "submitButton",
    "hcaptcha",
    "email",
    "failMessage",
    "success",
    "captchaResponseForm",
  ]

  static values = {
    currentUser: Boolean,
    email: String,
    path: String,
    captchaResponseForm: String
  }

  setCaptchaResponse({ detail: { captchaResponse } }) {
    this.captchaResponseFormValue = captchaResponse
    if (this.captchaResponseFormValue === "") {
      this.submitButtonTarget.classList.add("button-gray");
    }
    else {
      this.submitButtonTarget.classList.remove("button-gray");
    }
  }

  showCaptcha() {
    if (this.hasEmailTarget) this.emailValue = this.emailTarget.value

    //If the current user exists
    if (this.currentUserValue && !this.emailValue && this.hasEmailTarget) {
      this.emailTarget.focus();
      return false
    }

    //If Captcha is not yet there and there is no current user, simply show captcha widget
    if ((!this.emailValue || !this.captchaResponseFormValue) && !this.currentUserValue) {
      this.hcaptchaTarget.classList.remove("hidden"); // TODO: Switch to hidden attribute instead
      this.submitButtonTarget.classList.add("button-gray");
      if (!this.emailValue) this.emailTarget.focus();
      if (this.captchaResponseFormValue) this.submitButtonTarget.classList.remove("button-gray");
    }
    else {
      const dataForm = new FormData(this.element.querySelector('form'));
      var payload = Object.fromEntries(dataForm.entries());

      var that = this;

      fetch(this.pathValue, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken()
        },
        body: JSON.stringify(payload)
      })
      .then(function(response) {
        that.element.querySelector('form').classList.add("hidden");

        if (response.ok) {
          if (that.hasSuccessTarget) that.successTarget.classList.remove("hidden");
        } 
        else {
          that.failMessageTarget.classList.remove("hidden");
        }
      })
    }
  }
}