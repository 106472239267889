/* eslint no-console:0 */
import Rails from 'rails-ujs'

import CookiesEuBanner from 'cookies-eu-banner';
import '../src/js/sofa'

/* stimulus */
import "controllers"

/* turbo-rails */
// import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

Rails.start()

document.addEventListener("DOMContentLoaded", () => {
  new CookiesEuBanner(function () {
  }, true);
})