import { Controller } from "stimulus"
import flatpickr from "flatpickr";

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

export default class extends Controller {

  static targets = [ "pausedFrom", "pausedTill" ]

  static values = { expireDate: String }

  connect() {
    this.minDateTill = this.pausedFromTarget.value ? this.pausedFromTarget.value : '';
    this.endDateTill = this.minDateTill ? this.minDateTill : new Date()

    this.setPauseFrom()
    this.setPauseTill() 
  }
  
  // action
  setDate() {
    this.minDateTill = this.pausedFromTarget.value;
    this.endDateTill = this.pausedFromTarget.value ? this.pausedFromTarget.value : new Date();

    if (!this.pausedFromTarget.value || this.minDateTill >= this.endDateTill) {
      this.pausedTillTarget.value = ''
    }

    this.setPauseTill() 
  }

  setPauseFrom() {
    flatpickr(this.pausedFromTarget, {
      minDate: 'today', 
      maxDate: this.expireDateValue
    });
  }

  setPauseTill() {
    flatpickr(this.pausedTillTarget, {
      minDate: this.minDateTill ? this.minDateTill : 'today', 
      maxDate: new Date(this.endDateTill).fp_incr(6)
    });
  }
}

