import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {

  static targets = [ 'store' ]

  connect() {
    const select = this.element.querySelector('select')
    this.select = new SlimSelect({
      select: select,
      
      settings: {
        allowDeselect: true
      }
    })
    select.querySelectorAll('option')[0].setAttribute('data-placeholder', 'true')
  }

  selected(event) {
    this.storeTargets.forEach((store) => {
      store.classList.add('hidden');
      if (store.id === event.target.value) {
        store.classList.remove('hidden');
      }
    })
  }
}