import { Controller } from "stimulus"

export default class extends Controller {

  removeAsset(evt) {
    var parent = evt.target.closest('.nested-fields');
    parent.style.display = 'none';
    parent.querySelector('input').value = 1;
  }

  addAsset(event) {
    var selectFile = this.element.querySelector("template").content.cloneNode(true);
    this.element.insertBefore(selectFile, event.target.closest('.links')); 
  }
}
