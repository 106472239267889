import {} from 'assets/javascripts/epiceditor.js';

import { Controller } from "stimulus"

export default class extends Controller {

  static values = { styles: String }

  connect() {
    new EpicEditor({
      autogrow: true,
      container: this.element.querySelector("#epiceditor"),
      clientSideStorage: false,
      textarea: this.element.querySelector("#epiceditor_textarea"),
      theme: {
        preview: '/assets/application.css',
        base: this.stylesValue,
        editor: this.stylesValue
      }
    }).load();
  }
}