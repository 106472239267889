import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 'color','grey', "review", "content"]

  openContent(event) {
    this.index = event.target.id;

    this.decolorizeLogo();
    this.colorizeLogo();
    
    if (window.innerWidth <= 1000) {
      this.reviewTargets[this.index].classList.remove('hidden') 
    }
    else {
      this.reviewTargets[this.index].classList.add('hidden')
      this.contentTarget.classList.remove('hidden')
      this.contentTarget.innerHTML = this.reviewTargets[this.index].innerHTML
    }
  }

  close() {
    this.decolorizeLogo();
    this.reviewTargets[this.index].classList.add('hidden');  
    this.contentTarget.classList.add('hidden'); 
    this.index = null
  }

  colorizeLogo() {
    this.greyTargets[this.index].style.display = 'none'
    this.colorTargets[this.index].style.display = 'block'
  }

  decolorizeLogo() {
    this.reviewTargets.forEach((review, review_index) => {
      review.classList.add('hidden')
      this.greyTargets[review_index].removeAttribute('style')
      this.colorTargets[review_index].removeAttribute('style')
    })
  }

  moveOpenedLogoOnWindowResize() {
    if (this.index) {
      if (window.innerWidth <= 1000) {
        this.reviewTargets[this.index].classList.remove('hidden')
        this.contentTarget.classList.add('hidden')
      }
      else {
        this.reviewTargets[this.index].classList.add('hidden')
        this.contentTarget.classList.remove('hidden')
        this.contentTarget.innerHTML = this.reviewTargets[this.index].innerHTML
      }
    }
  }
}
