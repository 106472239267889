import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {

  static targets = [ 
    "servicePrice", 
    'buttonSave',  
    "priceTotal", 
    "totalPrice",
    'select'
  ]

  static values = { 
    initialService: Object,
    initialPrice: String,
    serviceFee: Number
  }

  connect() {
    const select = this.element.querySelector('select')

    this.select = new SlimSelect({
      select: select,

      settings: {
        disabled: this.initialServiceValue["value"] ? true : false,
        allowDeselect: true,
      }
    })

    if (this.initialServiceValue["value"]) {
      this.select.setData([this.initialServiceValue])
      this.servicePriceTarget.classList.remove('hidden')
    } 
    else {
      select.querySelectorAll('option')[0].setAttribute('data-placeholder', 'true')
    }

    this.setTotalPrice()
  }

  selectToggle() {
    
    this.servicePriceTarget.classList.toggle("hidden", this.selectTarget.value == '');

    this.priceTotalTarget.value = this.initialPriceValue
    this.setTotalPrice()
  }

  setTotalPrice() {
    this.buttonSaveTarget.classList.toggle("hidden", Number(this.priceTotalTarget.value) == 0 || !Number(this.priceTotalTarget.value))
    this.totalPriceTarget.innerHTML = `€${this.totalPrice(this.priceTotalTarget.value, this.serviceFeeValue)}`
  }

  totalPrice(price, serviceFee) {
    return (price - serviceFee * price / 100).toFixed(1)
  }
}